import "core-js/modules/es.array.push.js";
import { apiUsdkChannelGoodsUpdate } from '@/api/usdk';
import { getValue, setValue, formatJson } from '@/utils/util';
import fileToJson from '@ultra/share/utils/parseExcel.js';
import { ElMessageBox, ElMessage } from 'element-plus';
import { customDataReport } from '@/utils/dataReport';
import { UseGlobalConfig } from '@/utils/hooks';
export const UsePropConfig = () => {
  return {
    channel: {
      type: Object,
      default: null
    },
    gid: {
      type: Number,
      default: 0
    }
  };
};
const UseGoodsHook = (filterVal, props, headers, type) => {
  const confirmLoading = ref(false);
  const tableData = ref([]);
  const loadingTable = ref(false);
  const isConfirm = ref(false);
  const isEdit = ref(false);
  const oldTableData = ref([]);
  const changeData = ref([]);
  const isImport = ref(false);
  const {
    $channel
  } = UseGlobalConfig();
  /**
  * 筛选修改的数据
  * @returns Array
  */
  const filterChangeData = () => {
    const mapData = new Set();
    const oldMapData = new Set();
    const newData = tableData.value;
    const oldData = oldTableData.value;
    const len = newData.length;
    for (let i = 0; i < len; i++) {
      const n = newData[i];
      const o = oldData[i];
      delete n._X_ROW_KEY;
      delete o._X_ROW_KEY;
      if (filterVal.length && isImport.value) {
        filterVal.forEach(key => {
          if (getValue(key, n) != getValue(key, o)) {
            mapData.add(n);
            oldMapData.add(o);
          }
        });
      } else {
        if (JSON.stringify(n) !== JSON.stringify(o)) {
          mapData.add(n);
          oldMapData.add(o);
        }
      }
    }
    return {
      changeData: Array.from(mapData),
      noChangeData: Array.from(oldMapData)
    };
  };
  // 国内
  const createTableValue = tableData => {
    const result = [];
    tableData.forEach(data => {
      const obj = [];
      filterVal.forEach(filter => {
        obj.push(data[filter]);
      });
      result.push(obj);
    });
    return result;
  };
  // 全球
  const createTableValueGlobal = tableData => {
    return formatJson(filterVal, tableData);
  };
  const createHeader = () => {
    const header = ['游戏商品ID', '商品名称', '商品金额(元)', '币种', '商品描述'];
    if (props.channel.channel === $channel.OFFICIAL_IOS_CHANNEL) {
      header.push('AppStore商品ID');
    } else {
      header.push(`${props.channel.name}商品ID`);
    }
    // 强行创造一个二维表头 给后端用，真...
    const ljHeader = [header];
    return {
      ljHeader,
      header
    };
  };
  const createHeaderGlobal = (payTypes, openPays) => {
    const headers = ['游戏商品ID', '商品名称', '商品金额(元)', '币种', '商品描述', 'SDK商品ID'];
    payTypes.forEach(item => {
      if (openPays.includes(item.key)) {
        headers.push(item.value);
      }
    });
    return {
      header: headers,
      ljHeader: headers
    };
  };
  const handleEditGoods = (payTypes, openPays) => {
    if (isEdit.value) {
      const fn = () => {
        loadingTable.value = true;
        isEdit.value = false;
        const {
          changeData,
          noChangeData
        } = filterChangeData();
        apiUsdkChannelGoodsUpdate({
          gid: props.gid,
          cid: props.channel.cid,
          channel: props.channel.channel,
          data: JSON.stringify(changeData)
        }).then(res => {
          if (res.code === 0) {
            oldTableData.value = JSON.parse(JSON.stringify(tableData.value));
            isImport.value = false;
            customDataReport({
              eventName: 'set_goods_config',
              before: type === 'internal' ? createTableValue(noChangeData) : createTableValueGlobal(noChangeData),
              after: type === 'internal' ? createTableValue(changeData) : createTableValueGlobal(changeData),
              header: type === 'internal' ? createHeader().ljHeader : createHeaderGlobal(payTypes, openPays).ljHeader,
              operatePath: '运营-登录支付配置-支付和商品管理-商品管理',
              gid: props.gid
            });
          }
        }).finally(() => {
          loadingTable.value = false;
        });
      };
      if (!isConfirm.value) {
        isConfirm.value = true;
        ElMessageBox.confirm(`你正在编辑修改${props.channel.name}渠道支付商品ID，请务必谨慎操作保证无误后再保存，否则导致的线上支付事故后果自负！
        本次商品管理弹窗关闭前再次进行的修改编辑操作将不再弹出此提示。`, '提示', {}).then(() => {
          return fn();
        });
      } else {
        fn();
      }
    } else {
      isEdit.value = true;
    }
  };
  const inputRef = ref();
  const handlePatchInputClick = () => {
    var _inputRef$value;
    (_inputRef$value = inputRef.value) === null || _inputRef$value === void 0 ? void 0 : _inputRef$value.dispatchEvent(new MouseEvent('click'));
  };
  // 验证格式
  const fnGetExtension = file => {
    const fileName = file.name;
    const patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i; // eslint-disable-line
    const fileExtension = fileName.match(patternFileExtension);
    return fileExtension;
  };
  // 上传文件
  const handleFileChange = e => {
    let file = e.target.files[0];
    const accept = '.xls,.xlsx';
    if (!accept.includes(fnGetExtension(file)[1])) {
      ElMessage.warning(`请上传符合以下格式的文件：${accept}`);
    } else {
      try {
        loadingTable.value = true;
        fileToJson(file, data => {
          let _tableData = data[0].list;
          for (let i = 0; i < headers; i++) {
            _tableData.shift();
          }
          console.log('_tableData', _tableData);
          if (_tableData.length !== oldTableData.value.length) {
            ElMessage.error('商品导入失败！请确认商品条目数量是否匹配！');
          } else {
            _tableData.forEach((d, i) => {
              let len = d.length;
              for (let j = 0; j < len; j++) {
                setValue(tableData.value[i], filterVal[j], d[j]);
              }
            });
            isEdit.value = true;
            isImport.value = true;
          }
          loadingTable.value = false;
        });
      } catch (e) {
        ElMessage.warning('上传文件解析失败');
      }
    }
    // inputRef.value?.value = '';
  };
  return {
    confirmLoading,
    tableData,
    loadingTable,
    isConfirm,
    isEdit,
    oldTableData,
    changeData,
    isImport,
    handleEditGoods,
    handlePatchInputClick,
    handleFileChange,
    inputRef
  };
};
export default UseGoodsHook;