import { Fragment as _Fragment, createVNode as _createVNode } from "vue";
import { ElInput, ElInputNumber } from 'element-plus';
import { useModel } from '@ultra/share/hooks';
const InputComponent = defineComponent({
  name: 'InputComponent',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    onChange: {
      type: Function,
      default: () => {
        // to do
      }
    }
  },
  setup(props, ctx) {
    const value = useModel(() => props.type === 'number' ? Number(props.modelValue) || undefined : props.modelValue, val => ctx.emit('update:modelValue', val));
    const isDirty = ref(false);
    const dirtyStyle = {
      border: '1px solid #f56c6c'
    };
    watch(() => value.value, val => {
      if (!props.disabled) {
        isDirty.value = true;
      }
    });
    watch(() => props.disabled, val => {
      if (val) {
        isDirty.value = false;
      }
    });
    const onInput = val => {
      ctx.emit('update:modelValue', val);
      props.onChange && props.onChange(val);
    };
    return () => _createVNode(_Fragment, null, [props.type === 'number' ? _createVNode(ElInputNumber, {
      "onInput": onInput,
      "modelValue": value.value,
      "style": isDirty.value ? dirtyStyle : '',
      "controls": false,
      "disabled": props.disabled,
      "placeholder": props.placeholder
    }, null) : _createVNode(ElInput, {
      "onInput": onInput,
      "modelValue": value.value,
      "style": isDirty.value ? dirtyStyle : '',
      "disabled": props.disabled,
      "placeholder": props.placeholder
    }, null)]);
  }
});
export default InputComponent;