import { createVNode as _createVNode, isVNode as _isVNode } from "vue";
import { ElOption, ElSelect } from 'element-plus';
import { useModel } from '@ultra/share/hooks';
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
const SelectComponent = defineComponent({
  name: 'SelectComponent',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    onChange: {
      type: Function,
      default: undefined
    }
  },
  setup(props, ctx) {
    const value = useModel(() => props.modelValue, val => ctx.emit('update:modelValue', val));
    const isDirty = ref(false);
    const dirtyStyle = {
      border: '1px solid #f56c6c'
    };
    watch(() => value.value, val => {
      if (!props.disabled) {
        isDirty.value = true;
      }
    });
    watch(() => props.disabled, val => {
      if (val) {
        isDirty.value = false;
      }
    });
    return () => {
      let _slot;
      return _createVNode(ElSelect, {
        "modelValue": value.value,
        "onChange": props.onChange,
        "style": isDirty.value ? dirtyStyle : '',
        "disabled": props.disabled,
        "placeholder": props.placeholder
      }, _isSlot(_slot = props.options.map(option => _createVNode(ElOption, {
        "key": option[props.optionValue],
        "value": option[props.optionValue],
        "label": option[props.optionLabel]
      }, null))) ? _slot : {
        default: () => [_slot]
      });
    };
  }
});
export default SelectComponent;